import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { Field, Formik } from "formik";
import Button from "../../../components/Button";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import useGetPanDetails from "../../../containers/PanDetails/useGetPanDetails";
import Input from "../../../components/CustomInput";
import * as yup from "yup";
import { getPanCardDetails } from "../../../apis/coApplicant";
import { panNoRegex } from "../../../utils/regEx";
import { showToast } from "../../../utils/toast";
import { toastTypes } from "../../../utils/reusableConst";
import { PanDetailsContext } from "../../../containers/PanDetails";

const CapturePanDetails = () => {
    const {
        setOpenCamera,
        openCamera,
        dataUri,
        handleCapture,
        navigate,
        scanPanCardData,
        webcamRef,
        setActivePanDetailsPage,
        setPanCardDetails,
        setScanClick,
        panCardDetails,
        confirmPanCardDetails,
    } = useContext(PanDetailsContext);

    const [showSubmitBtn, setShowSubmitBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialValues = {
        panNo: "",
        name: "",
        dateOfBirth: "",
    };

    const handleInputChange = () => {
        setShowSubmitBtn(false);
    };

    const handleDetailButtonClicked = async (
        values: any,
        errors: any,
        setFieldValue: any
    ) => {
        try {
            setLoading(true);
            if (Object.keys(errors).length) return;
            const response = await getPanCardDetails(values.panNo);
            if (response?.dateOfBirth) {
                setLoading(false);
                setPanCardDetails(response);
                setShowSubmitBtn(true);
                setFieldValue("name", response?.name || "");
                setFieldValue("dateOfBirth", response?.dateOfBirth || "");
            } else {
                setLoading(false);
                showToast("Invalid Pan Card", toastTypes.error);
                setFieldValue("name", "");
                setFieldValue("dateOfBirth", "");
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="p-4 flex flex-col relative h-[100vh-100px] overflow-y-auto w-full">
                <VerifyDetailsHeader
                    title={"PAN Details"}
                    backClickHandler={() => navigate("/verify-details")}
                />
                <span className="text-neutral-3 mt-2 text-left">
                    Enter the details for easy loan approvals.
                </span>
                <div className="mt-8 mx-auto">
                    <img
                        src="/assets/icons/pan-img.svg"
                        alt="right-arrow-icon"
                    />
                </div>
                <div className="text-xl font-semibold mt-3 text-center">
                    Your PAN
                </div>
                <div className="text-neutral-3 mt-2 text-center">
                    Mandatory for Loan Application
                </div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        panNo: yup
                            .string()
                            .required("Pan No is required")
                            .matches(panNoRegex, "Invalid Pan No"),
                    })}
                    onSubmit={(values: any) => {}}
                >
                    {({ values, errors, setFieldValue }: any) => {
                        return (
                            <div>
                                <div className="flex flex-row  justify-between mb-16">
                                    <div className="w-full">
                                        <Input
                                            name={"panNo"}
                                            label={""}
                                            disabled={loading}
                                            labelClassName={"uppercase"}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {/* get details button */}
                                    <div className="ml-8 mt-2 w-1/3">
                                        <Button
                                            text={"Get Details"}
                                            type="primary"
                                            disabled={loading}
                                            onClick={() =>
                                                handleDetailButtonClicked(
                                                    values,
                                                    errors,
                                                    setFieldValue
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <Input
                                    name={"name"}
                                    label={"Name"}
                                    labelClassName={"uppercase"}
                                    inputClassName={"text-neutral-4"}
                                    disabled={true}
                                />
                                <div className="mb-4" />
                                <Input
                                    name={"dateOfBirth"}
                                    label={"Dob"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                    disabled={true}
                                />
                                <div className="mb-16" />
                            </div>
                        );
                    }}
                </Formik>
                <div
                    className={
                        "fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0  left-1/2 max-w-3xl mx-auto translate-x-[-50%]"
                    }
                >
                    <Button
                        text={"Confirm"}
                        type="primary"
                        onClick={() => {
                            confirmPanCardDetails(panCardDetails);
                        }}
                        disabled={!showSubmitBtn || loading}
                    />
                </div>
            </div>
        </>
    );
};

export default CapturePanDetails;
