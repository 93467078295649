import React, { MouseEventHandler, ReactNode } from "react";

interface Props {
    icon?: ReactNode;
    text: string;
    className?: string;
    onClick?: MouseEventHandler;
    type?: "primary" | "primaryOutline";
    disabled?: boolean;
    isSubmit?: boolean;
    ofInputHeight?: boolean;
    innerClassName?: string;
    id?: string;
    textClassname?: string;
}

const Button = (props: Props) => {
    const {
        icon,
        text,
        className,
        innerClassName,
        type = "primary",
        disabled = false,
        isSubmit,
        onClick,
        id,
    } = props;
    if (type === "primary") {
        return (
            <button
                className={`w-full rounded-xl ${
                    disabled ? "bg-neutral-5" : "bg-primary"
                }  py-3 text-base ${className}`}
                id={id}
                type={isSubmit ? "submit" : "button"}
                onClick={onClick}
                disabled={disabled}
            >
                <div
                    className={` ${innerClassName} w-full h-full center font-medium`}
                >
                    <div className={`center mr-1`}>{icon}</div>
                    <div className={`text-[#FFFFFF] uppercase`}>{text}</div>
                </div>
            </button>
        );
    } else if (type === "primaryOutline") {
        return (
            <button
                className={`w-full py-3 text-primary text-base rounded-xl border-2 border-primary ${className}`}
                id={id}
                type={isSubmit ? "submit" : "button"}
                onClick={(e) =>
                    !disabled && onClick ? onClick(e) : () => null
                }
                disabled={disabled}
            >
                <div
                    className={` ${innerClassName} w-full h-full center font-medium`}
                >
                    <div className={`center mr-1`}>{icon}</div>
                    <div className={`text-primary uppercase`}>{text}</div>
                </div>
            </button>
        );
    }
    return <div />;
};

export default Button;
