import React, { useEffect, useState } from "react";
import {
    deleteCoApplicantDocument,
    getAllCoApplicants,
    getCoApplicantDocs,
    viewDocument,
} from "../../../apis/coApplicant";
import { CoApplicantData } from "../types";
import { toast } from "react-toastify";

const useGetAllCoApplicants = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [showActions, setShowActions] = useState<any>();
    const [deleteDocModal, setDeleteDocModal] = useState<any>();
    const [data, setData] = useState<CoApplicantData[]>([]);
    const [docs, setDocs] = useState<any>([]);

    const fetchCoApplicants = async () => {
        try {
            setLoader(true);
            const response = await getAllCoApplicants();

            if (response?.data?.success) {
                setData(response?.data?.loanApplicants);
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };

    const fetchCoApplicantDocs = async (loanApplicantId: string) => {
        try {
            const response = await getCoApplicantDocs({
                loanApplicantId,
            });

            if (response?.data?.success) {
                setDocs((prevDataMap: any) => ({
                    ...prevDataMap,
                    [loanApplicantId]: response?.data?.data?.docs,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const viewDocumentHandler = async (payload: any) => {
        try {
            const response = await viewDocument(payload);

            if (response?.data?.success) {
                window.open(response?.data?.path);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteDocumentHandler = async (
        payload: any,
        loanApplicantId: any
    ) => {
        try {
            setLoader(true);
            const response = await deleteCoApplicantDocument(payload);

            if (response?.data?.success) {
                await fetchCoApplicantDocs(loanApplicantId);
                setDeleteDocModal(false);
                toast.success("Document deleted successfully!", {
                    toastId: "toastId",
                });
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCoApplicants();
    }, []);

    useEffect(() => {
        if (data?.length > 0) {
            const fetchData = async () => {
                for (const applicant of data) {
                    await fetchCoApplicantDocs(String(applicant.applicantId));
                }
            };

            fetchData();
        }
    }, [data?.length]);

    return {
        coApplicantsData: data,
        coApplicantDocs: docs,
        refetchCoApplicantDocs: fetchCoApplicantDocs,
        setLoader,
        loader,
        deleteDocModal,
        setDeleteDocModal,
        showActions,
        setShowActions,
        viewDocumentHandler,
        deleteDocumentHandler,
    };
};

export default useGetAllCoApplicants;
