import React, { createContext } from "react";
import MainContainer from "../MainContainer";
import useGetPanDetails from "./useGetPanDetails";
import CapturePanDetails from "../../layouts/PanDetails/CapturePanDetails";
import VerifyPanDetails from "../../layouts/PanDetails/VerifyPanDetails";
export const PanDetailsContext = createContext<any>("lll");

const PanDetails = () => {
    const {
        activePanDetailsPage,
        setActivePanDetailsPage,
        panCardDetails,
        setPanCardDetails,
        setScanClick,
        scanClick,
        setOpenCamera,
        openCamera,
        dataUri,
        handleCapture,
        navigate,
        scanPanCardData,
        webcamRef,
        confirmPanCardDetails,
    } = useGetPanDetails();

    return (
        <PanDetailsContext.Provider
            value={{
                activePanDetailsPage: activePanDetailsPage,
                setActivePanDetailsPage: setActivePanDetailsPage,
                panCardDetails: panCardDetails,
                setPanCardDetails: setPanCardDetails,
                setScanClick: setScanClick,
                scanClick: scanClick,
                setOpenCamera: setOpenCamera,
                openCamera: openCamera,
                dataUri: dataUri,
                handleCapture: handleCapture,
                navigate: navigate,
                scanPanCardData: scanPanCardData,
                webcamRef: webcamRef,
                confirmPanCardDetails: confirmPanCardDetails,
            }}
        >
            <MainContainer>
                {activePanDetailsPage === 0 && <CapturePanDetails />}
                {activePanDetailsPage === 1 && <VerifyPanDetails />}
            </MainContainer>
        </PanDetailsContext.Provider>
    );
};

export default PanDetails;
