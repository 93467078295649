const ActionCard = ({
    title,
    icon,
    isCompleted,
    onClick,
    isDisabled,
    isRejected,
    digilockerStatusLoading,
}: {
    title: string;
    icon: any;
    isCompleted?: boolean;
    onClick?: () => void;
    isDisabled: boolean;
    isRejected: boolean;
    digilockerStatusLoading?: boolean;
}) => {
    const actionableCardCondition =
        !isCompleted && !isDisabled && !digilockerStatusLoading;

    return (
        <div
            className={`flex items-center border p-4 rounded-xl w-full ${
                actionableCardCondition && "cursor-pointer"
            } 
            ${isCompleted && "bg-[#EFF8F4]"}
            ${isDisabled ? "opacity-50" : "opacity-100"} ${isRejected && "bg-[#FFE4E6]"}`}
            onClick={() => actionableCardCondition && onClick?.()}
        >
            <div className="flex items-center w-full">
                <div>
                    <img src={icon} />
                </div>
                <div className="flex flex-col ml-3">
                    <span className="">{title}</span>
                    {isCompleted && (
                        <span className="text-success font-semibold">
                            Completed
                        </span>
                    )}
                    {isRejected && (
                        <span className="text-failure font-semibold">
                            KYC Failed, please try again.
                        </span>
                    )}
                </div>
            </div>
            <div>
                {!isCompleted && !digilockerStatusLoading && (
                    <img
                        src="/assets/icons/right-arrow-icon.svg"
                        alt="right-arrow-icon"
                    />
                )}
                {digilockerStatusLoading && title === "Upload Aadhaar" && (
                    <div className="w-8 h-8 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                )}
            </div>
        </div>
    );
};

export default ActionCard;
