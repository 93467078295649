import axios, { AxiosRequestConfig } from "axios";
import {
    deleteCookie,
    getBinaryDecodedCookieValue,
    getCookie,
    setCookie,
} from "./cookies";

const httpClient = ({
    method,
    url,
    data,
    responseType,
    headers,
    params,
}: AxiosRequestConfig) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const { status } = error.response;
            if (status === 401) {
                deleteCookie(`${process.env.REACT_APP_SESSION_COOKIE_NAME}`);
            }
            return Promise.reject(error);
        }
    );

    const extendedHeaders = {
        "customer-token": `${getCookie(`${process.env.REACT_APP_SESSION_COOKIE_NAME}`)}`,
        ...headers,
    };

    return axios({
        method,
        url,
        data,
        headers: extendedHeaders,
        responseType,
        params,
    });
};

export default httpClient;
