import React, { useContext } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "../../../components/CustomInput";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import Button from "../../../components/Button";
import { PanDetailsContext } from "../../../containers/PanDetails";

const VerifyPanDetails = () => {
    const {
        confirmPanCardDetails,
        setActivePanDetailsPage,
        panCardDetails,
        scanClick,
    } = useContext(PanDetailsContext);

    return (
        <>
            <div className="p-4 flex flex-col relative h-[100vh-100px] overflow-y-auto w-full">
                <VerifyDetailsHeader
                    title={"Confirm Details"}
                    backClickHandler={() => setActivePanDetailsPage(0)}
                />
                <span className="text-neutral-3 mt-2 text-left">
                    Please confirm your PAN Details
                </span>
                <Formik
                    initialValues={panCardDetails || {}}
                    validationSchema={yup.object({
                        panNo: yup.string().required("Pan no is required"),
                    })}
                    onSubmit={(values: any) => {}}
                >
                    {(formikProps: any) => (
                        <div className="mt-10 flex flex-col gap-y-4">
                            <Input
                                name={"panNo"}
                                label={"Pan"}
                                labelClassName={"uppercase"}
                                inputClassName={"text-neutral-4"}
                                disabled
                            />
                            <Input
                                name={"name"}
                                label={"Name"}
                                labelClassName={"uppercase"}
                                inputClassName={"text-neutral-4"}
                                disabled={panCardDetails?.name}
                            />
                            <Input
                                name={"dateOfBirth"}
                                maxDate={new Date()
                                    .toISOString()
                                    .split("T")[0]
                                    .toString()}
                                type={"date"}
                                label={"Dob"}
                                inputClassName={"text-neutral-4"}
                                labelClassName={"uppercase"}
                                disabled={panCardDetails?.dateOfBirth}
                            />
                        </div>
                    )}
                </Formik>
                <div
                    className={
                        "fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"
                    }
                >
                    <Button
                        text={"Confirm"}
                        type="primary"
                        onClick={() => confirmPanCardDetails(panCardDetails)}
                    />
                </div>
            </div>
        </>
    );
};

export default VerifyPanDetails;
